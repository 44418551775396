import React from 'react';
import { useSelector } from 'react-redux';
import loadable from '@loadable/component';
import { useForceUpdateOnWindowResize } from '@zola/zola-ui/src/hooks/useForceUpdateOnWindowResize';
import { useExposeAuthModal } from '~/hooks/useExposeAuthModal';

import { getUserContext } from '~/selectors/user';
import { usePostAuthDesktopNavRolloutModal } from '~/components/common/modals/PostAuthDesktopNavRolloutModal/usePostAuthDesktopNavRolloutModal';

import '../../../stylesheets/nav/WebsiteNav.less';

const BabyNav = loadable(
  () => import(/* webpackChunkName: "baby-nav" */ '~/components/BabyNav/BabyNav'),
  { ssr: true }
);

/**
 * TODO:
 * BabyNav should be moved from the loadable component and SSR'd
 */

const BabyNavContainer = () => {
  useForceUpdateOnWindowResize();
  const userContext = useSelector(getUserContext);
  usePostAuthDesktopNavRolloutModal(userContext);
  useExposeAuthModal();

  return <BabyNav />;
};

export default BabyNavContainer;
