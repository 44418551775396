import React from 'react';
import { Provider } from 'react-redux';
import { render } from 'react-dom';
import Loadable from 'react-loadable';

import store from './store';
import BabyApp from './BabyApp';

import './stylesheets/footer/Footer.less';

const BabyFooter = Loadable({
  loader: () => import('@zola/zola-ui/src/components/footers/baby/BabyFooter'),
  loading: () => <div />,
});

const root = document.getElementById('root-nav');

render(
  <Provider store={store}>
    <BabyApp />
  </Provider>,
  root
);

// Inject the shared footer into root container for web-zola pages
const injectWebsiteFooter = () => {
  const websiteFooter = document.getElementById('website-footer');
  if (websiteFooter) {
    render(
      <Provider store={store}>
        <BabyFooter />
      </Provider>,
      websiteFooter
    );
  }
};

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', injectWebsiteFooter);
} else {
  injectWebsiteFooter();
}
